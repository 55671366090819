import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Image from "gatsby-image"
import SEO from "../components/SEO"

const About = ({ data:{ about:{nodes}}}) => {
  
  const {title, info, stack, img } = nodes[0];

  return <Layout>
    < SEO title="About Me" description="about pratik savla software engineer"/>
    <section className="about-page">
      <div className="section-center about-center">
        <Image fluid={img.childImageSharp.fluid} className="about-img" />
        <article className="about-text">
          <Title title={title}/>
          <p>{info}</p>
          <div className="about-stack">
            {stack.map((item) => {
              return <span key={item.id}>{item.title}</span>
            })}
          </div>
        </article>
      </div>
    </section>
  </Layout>
}

export const query = graphql`
  {
    about: allStrapiAbout {
      nodes {
        id
        title
        info
        img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
  }
`
export default About
